let counter = 0;
let timer, slides, slide_deck, slideWidth

const slideshow = document.querySelector(".slideshow");
slides = document.querySelector(".slides");
slide_deck = Array.from(slides.children)

slideWidth = slideshow.getBoundingClientRect().width;

timer = setInterval(nextSlide, 3000);

window.addEventListener("resize", () => {
    slideWidth = slideshow.getBoundingClientRect().width;
    nextSlide();
});


function nextSlide() {
    counter++;
    if (counter == slide_deck.length) {
        counter = 0;
    }
    let offset = -slideWidth * counter;
    slides.style.transform = `translateX(${offset}px)`;
}

function previousSlide() {
    counter--;
    if (counter < slide_deck.length) {
        counter = slide_deck.length - 1;
    }
    let offset = -slideWidth * counter;
    slides.style.transform = `translateX(${offset}px)`;
}
